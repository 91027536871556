// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/SettingsPage.css */

.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.settings-panel {
    background: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: 400px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

h3 {
    font-size: 18px;
    margin: 10px 0;
}

.settings-panel .MuiFormControlLabel-root {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.settings-panel .MuiTextField-root,
.settings-panel .MuiSelect-root {
    margin-bottom: 20px; /* Increase bottom margin for more space */
    width: 100%;
}

.settings-panel .MuiFormControl-root {
    margin-bottom: 20px; /* Ensure form controls have enough spacing */
    width: 100%;
}

.settings-panel .MuiInputBase-root {
    width: 100%;
}

.settings-panel .MuiButton-root {
    margin-top: 20px;
    width: 100%;
}

.hidden {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SettingsPage/SettingsPage.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;;IAEI,mBAAmB,EAAE,0CAA0C;IAC/D,WAAW;AACf;;AAEA;IACI,mBAAmB,EAAE,6CAA6C;IAClE,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* src/SettingsPage.css */\n\n.settings-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n}\n\n.settings-panel {\n    background: #f5f5f5;\n    border-radius: 10px;\n    padding: 20px;\n    margin: 10px;\n    width: 400px;\n}\n\nh1 {\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\nh2 {\n    font-size: 20px;\n    margin-bottom: 10px;\n}\n\nh3 {\n    font-size: 18px;\n    margin: 10px 0;\n}\n\n.settings-panel .MuiFormControlLabel-root {\n    margin-bottom: 15px;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.settings-panel .MuiTextField-root,\n.settings-panel .MuiSelect-root {\n    margin-bottom: 20px; /* Increase bottom margin for more space */\n    width: 100%;\n}\n\n.settings-panel .MuiFormControl-root {\n    margin-bottom: 20px; /* Ensure form controls have enough spacing */\n    width: 100%;\n}\n\n.settings-panel .MuiInputBase-root {\n    width: 100%;\n}\n\n.settings-panel .MuiButton-root {\n    margin-top: 20px;\n    width: 100%;\n}\n\n.hidden {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
