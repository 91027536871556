import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import StudentAnswersGrid from "../blocks/StudentAnswersGrid";
import QuizEndView from "./QuizEndView";
import useWebSocket from "react-use-websocket"; // Adjust the import path as needed
import {Store} from 'react-notifications-component';

const StudentAnswerView = () => {
    const [question, setQuestion] = useState(null);
    const { code } = useParams();
    const [quizSession, setQuizSession] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [quizEnded, setQuizEnded] = useState(false);
    const [skipPowerUp, setSkipPowerUp] = useState(false);

    let url = `wss://api.edukona.com/ws/student/join/${code}/`;
    const sid = localStorage.getItem('sid');

    const handleIncomingMessage = (event) => {
        const receivedData = JSON.parse(event.data);
        console.log('Received data:', receivedData);
        if (receivedData.type === 'next_question') {
            setIsSubmitted(false);
            setQuestion(receivedData.question);
            setAnswers(receivedData.order);
            setQuizSession(receivedData.quiz_session);
            setLoading(false); // Stop loading when the question is received
            checkSubmissionStatus(receivedData.question.id); // Check if already submitted
        } else if (receivedData.type === 'quiz_ended') {
            setQuizEnded(true);
            setLoading(false);
        } else if (receivedData.type === 'quiz_started') {
            setLoading(true);
        } else if (receivedData.type === 'skip_power_up_granted') {
            setSkipPowerUp(true);
            Store.addNotification({
                title: "Power Up Granted",
                message: "You've been granted the SKIP power up.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            })
        } else if (receivedData.type === 'skip_power_up_used') {
            localStorage.setItem(`submitted_${question.id}_${code}_${sid}`, "skipped");
            setSkipPowerUp(false);
            setIsSubmitted(true);
            Store.addNotification({
                title: "Question Skipped",
                message: "You have skipped the current question.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    };

    const { sendMessage } = useWebSocket(url, {
        onMessage: handleIncomingMessage,
        onOpen: () => console.log("WebSocket connected"),
        onClose: () => console.log("WebSocket disconnected"),
        onError: (event) => console.error("WebSocket error", event),
    });

    const checkSubmissionStatus = (questionId) => {
        const storedSubmission = localStorage.getItem(`submitted_${questionId}_${code}_${sid}`);
        setIsSubmitted(!!storedSubmission);
    };

    const handleSkipQuestion = () => {
        console.log('Skipping question:', question.id);
        console.log('Data Sent', {type: 'skip_question',
            data: {
                student: { id: sid },
                quiz_session_code: code,
                question_id: question.id}
        });

        sendMessage(JSON.stringify({
            type: 'skip_question',
            data: {
                student: { id: sid },
                quiz_session_code: code,
                question_id: question.id

            }
        }));
    };

    return (
        <div>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : quizEnded ? (
                <QuizEndView />
            ) : question && quizSession ? (
                <>
                    <StudentAnswersGrid
                        question={question}
                        sendMessage={sendMessage}
                        answers={answers}
                        code={code}
                        isSubmitted={isSubmitted}
                        setIsSubmitted={setIsSubmitted} // Pass setIsSubmitted to child
                        quizSession={quizSession}
                    />
                    <Box textAlign="right" p={2}>
                        {skipPowerUp && !isSubmitted && (
                            <Button variant="contained" color="secondary" onClick={handleSkipQuestion} style={{ marginRight: '10px' }}>
                                Skip Question
                            </Button>
                        )}
                        <Button variant="contained" color="primary" onClick={() => sendMessage(JSON.stringify({ type: 'next_question' }))}>
                            Next Question
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <Typography>Unable to load question. Please try again.</Typography>
                    <Button variant="contained" color="primary" onClick={() => sendMessage(JSON.stringify({ type: 'next_question' }))}>
                        Refresh
                    </Button>
                </>
            )}
        </div>
    );
};

export default StudentAnswerView;
