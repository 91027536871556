import React from 'react';
import {Button, ButtonBase, Typography} from '@mui/material';
import { styled } from '@mui/system';

const getColor = (index) => {
    const colors = ['#e91e63', '#9c27b0', '#2196f3', '#00bcd4'];
    return colors[index % colors.length]; // Cycle through colors based on index
};

const StyledButtonBase = styled(ButtonBase)(({ theme, index, disabled}) => ({
    backgroundColor: getColor(index), // Set background color based on index
    textAlign: 'center',
    width: '100%',
    minHeight: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
        opacity: 0.9,
        boxShadow: theme.shadows[4],
    },
    color: theme.palette.getContrastText(getColor(index)),
    ...(disabled && {
        backgroundColor: theme.palette.action.disabledBackground, // Example disabled background
        color: theme.palette.text.disabled, // Example disabled text color
        pointerEvents: 'none', // Ensures the button doesn't react to interactions
        opacity: 0.5, // Adjust as needed to indicate disabled state visually
    }),
}));
const StudentAnswerOption = ({ answer, index, onClick, isSelected, isSubmitted }) => {
    return (
        <StyledButtonBase
            variant="contained"
            onClick={onClick}
            disabled={isSubmitted}
            index={index}
            // Adjust color or style if needed based on isSelected
        >
            <Typography variant="h4">{answer}</Typography>
        </StyledButtonBase>
    );
};

export default StudentAnswerOption;
